import React from "react";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import BigTextLittleText from "./BigTextLittleText";
import VerticalLine from "./VerticalLine";
import HeroH from "../images/25.jpg"
import HeroV from "../images/26.jpg"
import "../styles.css";
import ToutContent from "./ToutContent";
import Fade from "react-reveal/Fade";

const tradizione_images = [
    require("../images/34.jpg"),
    require("../images/35.jpg"),

]

const paesaggio_images = [
    require("../images/33.jpg"),
]

const quiete_images = [
    require("../images/0.jpeg"),
]
const texts = {
    intro: {
        it: {
            text: "Che vogliate passare un periodo di assoluto relax o che la vostra passione siano il mare o la visita " +
                "di borghi pittoreschi, Lamia Kalé farà al caso vostro.",
            slider: ""
        },
        en: {
            text: "Whether you want to spend a period of absolute relaxation or your passion is the sea or visiting picturesque villages, Lamia Kalé is for you.",
            slider: ""
        }
    },
    relax: {
        it: {
            text: "Nel primo caso potreste svegliarvi al mattino, quando i primi raggi di sole entrano in casa, e svolgere " +
                "la vostra giornata secondo i ritmi lenti della natura, un bagno in piscina e una passeggiata nei dintorni",
            slider: ""
        },
        en: {
            text: "IN THE FIRST CASE YOU COULD WAKE UP IN THE MORNING, WHEN THE FIRST RAYS OF SUN ENTER THE HOUSE, AND " +
                "SPEND YOUR DAY ACCORDING TO THE SLOW RHYTHMS OF NATURE, A SWIMMING POOL AND A WALK IN THE SURROUNDINGS.",
            slider: ""
        }
    },
    sport: {
        it: {
            text: "Se amate la bicicletta, la fitta rete di tortuose e ondulate strade di campagna, con vista su distese di ulivi secolari, farà al caso vostro. " +
                "Se invece amate l’esplorazione del territorio è necessario dotarsi di un’automobile. La posizione strategica di Lamia Kalé, " +
                "tra Alto Salento e Valle d’Itria, vi consente di spaziare tra mare, collina e città.",
            slider: "Sport"
        },
        en: {
            text: "IF YOU LOVE BICYCLES, THE DENSE NETWORK OF TWISTY AND WAVY COUNTRYSIDE ROADS, WITH A VIEW ON LANDS OF " +
                "SECULAR OLIVE TREES, WILL MAKE FOR YOU. IF YOU LOVE EXPLORING THE TERRITORY, YOU NEED TO EQUIP A CAR. THE " +
                "STRATEGIC POSITION OF LAMIA KALÉ, BETWEEN HIGH SALENTO AND VALLE D’ITRIA, ALLOWS YOU TO SPACE BETWEEN SEA, HILLS AND CITY.",
            slider: "Sport"
        }
    },
    avventura: {
        it: {
            text: "Essendo quasi equidistante dai due mari, 30km dal Mar Adriatico e 40km dal Mar Ionio, l'ubicazione della " +
                "Lamia offre la possibilità di scegliere tra calette sabbiose e scogliere rocciose. \n" +
                "Nel raggio di 50km si possono raggiungere splendide località di mare, tra le quali Polignano, Monopoli, " +
                "Savelletri e Porto Cesareo; oppure nell’entroterra magnifici borghi quali Ostuni, Cisternino, Martina Franca, " +
                "Alberobello, Locorotondo e Grottaglie. \n",
            slider: "Esplorazione"
        },
        en: {
            text: "BEING ALMOST EQUIDISTANT FROM THE TWO SEAS, 30KM FROM THE ADRIATIC SEA AND 40KM FROM THE IONIAN SEA, " +
                "LOCATION OF THE LAMIA OFFERS THE POSSIBILITY TO CHOOSE BETWEEN SANDY COVES AND ROCKY CLIFFS. \n WITHIN " +
                "50KM YOU CAN REACH BEAUTIFUL SEA LOCATIONS, BETWEEN POLIGNANO, MONOPOLI, SAVELLETRI AND PORTO CESAREO; " +
                "OR IN THE Hinterland, MAGNIFICENT VILLAGES SUCH AS OSTUNI, CISTERNINO, MARTINA FRANCA, ALBEROBELLO, " +
                "LOCOROTONDO AND GROTTAGLIE.",
            slider: "Explore"
        }
    }
}


const VitaContainer = (props) => {
    React.useEffect(() => {
        props.setNav(true);
        props.setFoot(true);
    });

    return(
        <ParallaxProvider>
            <div className="App">
                <div className="w-screen h-screen page-hero"
                     style={{backgroundImage: `url(${window.innerWidth >= 560 ? HeroH : HeroV})`}}/>
                />
                <Fade bottom>
                    <div className={"text-central"}>
                        {texts.intro[props.lang]["text"]}
                    </div>
                </Fade>
                <div id={"lineIntro"}/>
                <VerticalLine lineTrigger={"#lineIntro"}/>
                <div className="bg-quiete w-full">
                    <ToutContent
                        textDirection="toutContent w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row text-left sm:text-right bg-quiete"
                        text={texts.relax[props.lang]["text"]}
                        image={quiete_images[Math.floor(Math.random() * quiete_images.length)]}
                        alt={"image"}
                        class={"toutText--small"}
                    />
                </div>

                <BigTextLittleText title={texts.sport[props.lang]["slider"]} text={""} lineTrigger={"lineSport"}
                                   class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineSport"}/>
                <div className="bg-natura w-full">
                    <ToutContent
                        textDirection="toutContent w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row-reverse text-left bg-natura"
                        text={texts.sport[props.lang]["text"]}
                        image={paesaggio_images[Math.floor(Math.random() * paesaggio_images.length)]}
                        alt={"image"}
                        class={"toutText--small"}
                    />
                </div>
                <BigTextLittleText title={texts.avventura[props.lang]["slider"]} text={""} lineTrigger={"lineEsplorazione"}
                                   class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineEsplorazione"}/>
                <div className="bg-tradizione w-full">
                    <ToutContent
                        textDirection="toutContentLast w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row text-left sm:text-right bg-tradizione"
                        text={texts.avventura[props.lang]["text"]}
                        image={tradizione_images[Math.floor(Math.random() * tradizione_images.length)]}
                        alt={"image"}
                        class={"toutText--small"}
                    />
                </div>
            </div>
        </ParallaxProvider>
    )
};

export default VitaContainer;
