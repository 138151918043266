import React from "react";
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Image,
    Heading,
} from "./FooterStyles";

const texts = {
    it: {
        address: {
            title: "Dove siamo",
            content: "Via Madonna della Grotta, 72013, 72013 Ceglie Messapica BR"
        },
        contacts: {
            title: "Contatti",
            content: [{
                type: "Email",
                link: "mailto:lamiakaleceglie@gmail.com",
                contact: "lamiakaleceglie@gmail.com"
            }, {
                type: "Telefono",
                link: "tel:+393460938264",
                contact: "+393460938264"
            }]
        },
        social: {
            title: "Social",
            content: [{
                social: "Instagram",
                link: "https://instagram.com/lamia_kale?utm_medium=copy_link"
            }]
        }
    },
    en: {
        address: {
            title: "Address",
            content: "Via Madonna della Grotta, 72013, 72013 Ceglie Messapica BR"
        },
        contacts: {
            title: "Contacts",
            content: [{
                type: "Email",
                link: "mailto:lamiakaleceglie@gmail.com",
                contact: "lamiakaleceglie@gmail.com"
            }, {
                type: "Telefono",
                link: "tel:+393460938264",
                contact: "+393460938264"
            }]
        },
        social: {
            title: "Social",
            content: [{
                social: "Instagram",
                link: "https://instagram.com/lamia_kale?utm_medium=copy_link"
            }]
        }
    }
}
const Footer = props => {
    const [lang, setLang] = React.useState(window.location.href.split("/")[3])


    return (
        <Box>
            <Container>
                <Row>
                    <Column key={`logo-col`}>
                        <Image/>
                    </Column>
                    <Column key={`address-col`}>
                        <Heading key={`address-heading`}>{texts[lang].address.title}</Heading>
                        <a href={"https://www.google.com/maps/place/Lamia+Kal%C3%A9/@40.6094069,17.5680314,17z/data=!4m12!1m6!3m5!1s0x134659610317a947:0xa5090e0551ff8caa!2sLamia+Kal%C3%A9!8m2!3d40.6085166!4d17.5685232!3m4!1s0x134659610317a947:0xa5090e0551ff8caa!8m2!3d40.6085166!4d17.5685232"}>{texts[lang].address.content}</a>
                    </Column>
                    <Column key={`contacts-col`}>
                        <Heading key={`contacts-heading`}>{texts[lang].contacts.title}</Heading>
                        {
                            texts[lang].contacts.content.map(c => (
                                <div key={c.type}>
                                    <label key={`${c.type}-label`}> {c.type}:</label><br/>
                                    <a href={c.link} key={`${c.type}-contact`}>{c.contact}</a>
                                </div>
                            ))
                        }
                    </Column>
                    <Column key={`social-col`}>
                        <Heading key={`social-heading`}>{texts[lang].social.title}</Heading>
                        {
                            texts[lang].social.content.map(s => (
                                <FooterLink key={s.social} href={s.link}>
                                    <i className="fab fa-instagram">
                                        <span style={{marginLeft: "10px"}}>
                                            {s.social}
                                        </span>
                                    </i>
                                </FooterLink>
                            ))
                        }
                    </Column>
                </Row>
            </Container>
        </Box>
    );
};
export default Footer;
