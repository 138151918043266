import React from "react";
import Fade from "react-reveal/Fade";
import {Parallax, useController} from "react-scroll-parallax";
import BigText from "./BigText";

const ParallaxCache = () => {
    const {parallaxController} = useController();

    React.useLayoutEffect(() => {
        const handler = () => parallaxController.update();
        window.addEventListener('load', handler);
        return () => window.removeEventListener('load', handler);
    }, [parallaxController]);

    return null;
};


const ParallaxBig = props => (
    <div className="w-full overflow-hidden relative ">
        <Parallax
            className="leading-none absolute left-0 right-0 pb-32 hidden sm:block"
            x={[100, -100]}
            tagOuter="figure"
        >
            <ParallaxCache/>
            <div className="background-big-text relative text-center font-bold mx-auto my-0 opacity-5">
                {props.title}
            </div>
        </Parallax>
        <div className={props.class + " relative left-0 right-0 text-center font-bold mx-auto my-0 p-2 top-0"}>
            <div id={props.lineTrigger}/>
        </div>
    </div>
)

const SmallText = props => (
    <Fade bottom>
        <div className={props.class + " relative left-0 right-0 text-center font-bold mx-auto my-0 p-2 top-0"}>
            {props.title}
            <div id={props.lineTrigger}/>
        </div>
    </Fade>
)


const BigTextLittleText = props => {
    const [width, setWidth] = React.useState(window.innerWidth)

    React.useEffect(() => {
        function handleResize() {
            setWidth({
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    return (
        <div>
            {
                width >= 640 ? <ParallaxBig title={props.title} lineTrigger={props.lineTrigger} class={props.class}/> :
                    <SmallText title={props.title} lineTrigger={props.lineTrigger} class={props.class}/>
            }
        </div>
    )
};

export default BigTextLittleText;
