import React from "react";
import ToutCopy from "./ToutCopy";
import ToutImage from "./ToutImage";

const ToutContent = props => (
    <div className={props.textDirection}>
        <ToutCopy text={props.text} class={props.class} withLink={props.withLink} textCta={props.textCta}/>
        <ToutImage fullWidth={props.fullWidth} image={props.image} alt={props.alt}/>
    </div>
);

export default ToutContent;
