import React from "react";
import { useNavigate } from "react-router-dom";
import HeroH from "../images/landing.jpg"
import HeroV from "../images/landing-vert.jpg"
import "../styles.css";


const languages = [
    {code: 'en', name: 'English'},
    {code: 'it', name: 'Italiano'}
]

const LanguageSwitcher = (props) => {

    const changeLanguageHandler = (lang) => {
        props.handleLanguageSelection(lang);
    }
    return (
        <div className="languageSwitcher">
            <h2>
                Lamia Kalé
            </h2>
            <LanguageSwitcherSelector
                handleLanguageSelection={changeLanguageHandler}
            />
        </div>
    )

}

const LanguageSwitcherSelector = (props) => {
    const navigate = useNavigate();

    const onChange = (e) => {
        props.handleLanguageSelection(e.target.className);
        navigate(`${e.target.className}/intro`);
    }
    const options = languages.map(language => {
        return <li key={language.code + "-li"} onClick={onChange}>
            <div key={language.code} value={language.code} className={language.code}>{language.name}</div>
        </li>
    });

    return(
        <div className="lang">
            <ul className="dropdown">
                {options}
            </ul>
        </div>
    )
}

const Landing = (props) => (
    <div className="App-landing">
        <div className={"w-screen h-screen page-hero"}
             style={{backgroundImage: `url(${window.innerWidth <= 560 ? HeroV : HeroH})`}}>
            <LanguageSwitcher
                handleLanguageSelection={props.handleLanguageSelection}
            />
        </div>
    </div>
);


export default Landing;
