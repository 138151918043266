import React from "react";
import ReactDOM from "react-dom";
import NavBar from "./components/NavBar";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Parallax from "./components/Parallax";
import Footer from "./components/Footer";
import HomeContainer from "./components/HomeContainer";
import VitaContainer from "./components/VitaContainer";
import DintorniContainer from "./components/DintorniContainer";
import Landing from "./components/Landing";


const App = () => {
    const [lang, setLang] = React.useState("");
    const [nav, setNav] = React.useState(false);
    const [foot, setFoot] = React.useState(false);


    return (
        <Router>
            {nav && <NavBar/>}
            <Routes>
                <Route path="/" exact
                       element={
                           <Landing
                               handleLanguageSelection={setLang}
                           />
                       }/>
                <Route
                    path="/it/intro"
                    exact
                    element={<Parallax lang={"it"} setNav={setNav} setFoot={setFoot}/>}
                />
                <Route
                    path="/it/casa"
                    exact
                    element={<HomeContainer lang={"it"} setNav={setNav} setFoot={setFoot}/>}
                />
                <Route
                    path="/it/dintorni"
                    exact
                    element={<DintorniContainer lang={"it"} setNav={setNav} setFoot={setFoot}/>}
                />
                <Route
                    path="/it/vita"
                    exact
                    element={<VitaContainer lang={"it"} setNav={setNav} setFoot={setFoot}/>}
                />
                <Route
                    path="/en/intro"
                    exact
                    element={<Parallax lang={"en"} setNav={setNav} setFoot={setFoot}/>}
                />
                <Route
                    path="/en/casa"
                    exact
                    element={<HomeContainer lang={"en"} setNav={setNav} setFoot={setFoot}/>}
                />
                <Route
                    path="/en/dintorni"
                    exact
                    element={<DintorniContainer lang={"en"} setNav={setNav} setFoot={setFoot}/>}/>
                <Route
                    path="/en/vita"
                    exact
                    element={<VitaContainer lang={"en"} setNav={setNav} setFoot={setFoot}/>}
                />
            </Routes>
            {foot && <Footer lang={lang}/>}
        </Router>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App/>, rootElement);
