import React from "react";
import {ParallaxProvider} from "react-scroll-parallax";
import VerticalLine from "./VerticalLine";
import HeroH from "../images/14.jpeg"
import HeroV from "../images/terreno.jpg"
import "../styles.css";
import Fade from "react-reveal/Fade";
import {FaBed, FaSwimmingPool, FaShower, FaParking} from 'react-icons/fa';
import {IconContext} from "react-icons";
import {MdOutlineNaturePeople, MdOutlineMonitor} from "react-icons/md";
import YoutubeEmbed from "./YouTubeEmbed";
import {FcWiFiLogo} from "react-icons/fc";
import CardCollection from "./CardCollection";


const texts = {
    intro: {
        it: "La casa, recentemente ristrutturata, per la sua conformazione offre ospitalità per un massimo di 6 persone. \n " +
            "Sono presenti due camere con letto matrimoniale, una cucina a gas completamente attrezzata, un soggiorno " +
            "con divano letto, bagno con doccia e lavatrice.",
        en: "The house, recently renovated, due to its conformation offers hospitlity for up to 6 people. \n" +
            "There are two bedrooms with double bed, a fully equipped gas kitchen, a living room with sofa bed, " +
            "bathroom with shower and washing machine."
    },
    house_details: {
        it: ["X4 + 2 posti letto","4x10x1.2 piscina","1 ettaro di natura","WiFi interno","1 bagno","1 tv","Parcheggio privato"],
        en: ["X4 + 2 beds","4x10x1.2 pool","1 hectar of nature","Internal WiFi","1 bathroom","1 tv","Private parking"],
    }
}

const HouseDetails = (props) => (
    <div className="home-intro--description w-full sm:w-1/2">
        <Fade bottom>
            <IconContext.Provider value={{color: "white", className: "description-item--icon"}}>
                <div className={"description-item"}>
                    <FaBed/> <h3>{texts.house_details[props.lang][0]} </h3>
                </div>
                <div className={"description-item"}>
                    <FaSwimmingPool/> <h3>{texts.house_details[props.lang][1]} </h3>
                </div>
                <div className={"description-item"}>
                    <MdOutlineNaturePeople/>{texts.house_details[props.lang][2]} <h3> </h3>
                </div>
                <div className={"description-item"}>
                    <FcWiFiLogo/> <h3>{texts.house_details[props.lang][3]} </h3>
                </div>
                <div className={"description-item"}>
                    <FaShower/> <h3>{texts.house_details[props.lang][4]} </h3>
                </div>
                <div className={"description-item"}>
                    <MdOutlineMonitor/> <h3>{texts.house_details[props.lang][5]} </h3>
                </div>
                <div className={"description-item"}>
                    <FaParking/> <h3>{texts.house_details[props.lang][6]} </h3>
                </div>
            </IconContext.Provider>
        </Fade>
    </div>
)

const YouTubeVideo = () => (
    <div className="video-responsive">
        <h1>Lamia Kalé</h1>
        <YoutubeEmbed embedId="uBb44hmBtpU"/>
    </div>
)

const HomeContainer = (props) => {
    React.useEffect(() => {
        props.setNav(true);
        props.setFoot(true);
    });

    return (
        <ParallaxProvider>
            <div className="App">
                <div className="w-screen h-screen page-hero"
                     style={{backgroundImage: `url(${window.innerWidth <= 560 ? HeroH : HeroV})`}}/>
                <Fade bottom>
                    <div className={"home-intro"}>
                        <HouseDetails lang={props.lang}/>
                        <YouTubeVideo/>
                    </div>
                </Fade>
                <Fade bottom>
                    <div className={"text-central"}>
                        {texts.intro[props.lang]}
                    </div>
                </Fade>
                <div id={"lineTrigger"}/>
                <VerticalLine lineTrigger={"#lineTrigger"}/>
                <div className="flex w-full">
                    <CardCollection lang={props.lang}/>
                </div>
            </div>
        </ParallaxProvider>
    )
};

export default HomeContainer;
