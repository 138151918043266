import React from "react";
import classnames from "classnames";
import OnVisible from "react-on-visible";
import ArticleHeader from "./ArticleHeader";
import ViewArticle from "./ViewArticle";

const Card = props => (
  <OnVisible
    visibleClassName="card-appear"
    percent={50}
    className={classnames(
      "article-card p-8 pb-0",
      props.cardMobile,
      props.cardCollection
    )}
  >
      <img src={props.data.image} alt={props.data.alt}/>
    <ArticleHeader text={props.data.desc[props.lang]} />
  </OnVisible>
);

export default Card;
