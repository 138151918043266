import React from "react";
import classnames from "classnames";

const Cta = props => (
  <div
    className={classnames(
      "cta text-center text-white w-auto relative inline-flex mt-12 p-4 px-8 cursor-pointer rounded-full",
      props.ctaBg
    )}
  >
    <a href={props.withLink} className="font-bold">
      {props.text}
    </a>
  </div>
);

export default Cta;
