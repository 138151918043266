import React from "react";
import {ParallaxProvider} from "react-scroll-parallax";
import BigTextLittleText from "./BigTextLittleText";
import VerticalLine from "./VerticalLine";
import HeroH from "../images/22.jpg"
import HeroV from "../images/logo-vert.jpg"
import "../styles.css";
import ToutContent from "./ToutContent";

const tradizione_images = [
    require("../images/1.jpeg"),
    require("../images/6.jpeg"),
    require("../images/10.jpeg"),
    require("../images/11.jpeg"),
    require("../images/17.jpeg"),
    require("../images/20.jpeg"),
]

const paesaggio_images = [
    require("../images/4.jpeg"),
    require("../images/5.jpeg"),
    require("../images/8.jpeg"),
    require("../images/19.jpeg"),
]

const quiete_images = [
    require("../images/0.jpeg"),
    require("../images/2.jpeg"),
    require("../images/14.jpeg"),
]
const texts = {
    lamia_home: {
        it: {
            header: "Scopri come rilassarti cullato dal delicato fruscio del vento che accarezza gli ulivi.",
            cta: "Esplora casa",
            slider: "Quiete"
        },
        en: {
            header: "Discover how to relax lulled by the gentle rustle of the wind that caresses our olive trees.",
            cta: "Discover home",
            slider: "Quiet"

        }
    },
    dintorni_home: {
        it: {
            header: "Ceglie Messapica, terra dalle antiche origini, è pronta ad accoglierti e farti assaporare la vera Puglia.",
            cta: "Esplora dintorni",
            slider: "Natura"

        },
        en: {
            header: "Ceglie Messapica, a land of ancient origins, is ready to welcome you and let you taste the real Puglia. ",
            cta: "Discover surroundings",
            slider: "Nature"

        }
    },
    vita_home: {
        it: {
            header: "Esplora le numerose attività che offre il territorio. Ecco una piccola guida per le tue vacanze in Puglia.",
            cta: "Esplora vita",
            slider: "Tradizione"

        },
        en: {
            header: "Explore the many activities that the area offers. Here is a small guide for your holidays in Puglia.\n",
            cta: "Discover life in Puglia",
            slider: "Tradition"

        }
    }


}


const Parallax = (props) => {
    React.useEffect(() => {
        props.setNav(true);
        props.setFoot(true);
    });

    return(
        <ParallaxProvider>
            <div className="App">
                <div className="w-screen h-screen page-hero"
                     style={{backgroundImage: `url(${window.innerWidth >= 1000 ? HeroH : HeroV})`}}/>
                <BigTextLittleText title={texts.lamia_home[props.lang]["slider"]} text={""} lineTrigger={"lineQuiete"} class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineQuiete"}/>
                <div className="bg-quiete w-full">
                    <ToutContent
                        textDirection="toutContent w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row text-left sm:text-right bg-quiete"
                        text={texts.lamia_home[props.lang]["header"]}
                        image={quiete_images[Math.floor(Math.random() * quiete_images.length)]}
                        alt={"image"}
                        class={"toutText"}
                        withLink={`/${props.lang}/casa`}
                        textCta={texts.lamia_home[props.lang]["cta"]}
                    />
                </div>

                <BigTextLittleText title={texts.dintorni_home[props.lang]["slider"]} text={"Natura"} lineTrigger={"lineNatura"}
                                   class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineNatura"}/>
                <div className="bg-natura w-full">
                    <ToutContent
                        textDirection="toutContent w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row-reverse text-left bg-natura"
                        text={texts.dintorni_home[props.lang]["header"]}
                        image={paesaggio_images[Math.floor(Math.random() * paesaggio_images.length)]}
                        alt={"image"}
                        class={"toutText"}
                        withLink={`/${props.lang}/dintorni`}
                        textCta={texts.dintorni_home[props.lang]["cta"]}
                    />
                </div>
                <BigTextLittleText title={texts.vita_home[props.lang]["slider"]} text={"Tradizione"} lineTrigger={"lineTradizione"}
                                   class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineTradizione"}/>
                <div className="bg-tradizione w-full">
                    <ToutContent
                        textDirection="toutContentLast w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row text-left sm:text-right bg-tradizione"
                        text={texts.vita_home[props.lang]["header"]}
                        image={tradizione_images[Math.floor(Math.random() * tradizione_images.length)]}
                        alt={"image"}
                        class={"toutText"}
                        withLink={`/${props.lang}/vita`}
                        textCta={texts.vita_home[props.lang]["cta"]}
                    />
                </div>
            </div>
        </ParallaxProvider>
    )
};

export default Parallax;
