import React, {Fragment} from "react";
import Card from "./Card";

const cards = [
    {
        image: require("../images/camera_padronale.jpg"),
        desc: {
            it:"Camera matrimoniale",
            en:"Double bedroom"
        },
        alt: "camera padronale"
    },
    {
        image: require("../images/cameretta.jpg"),
        desc: {
            it: "Camera matrimoniale",
            en: "Double bedroom"
        },
        alt: "Cameretta"
    }
    ,
    {
        image: require("../images/bagno.jpg"),
        desc: {
            it:"Bagno finestrato",
            en: "Windowed bathroom"
        },
        alt: "Bagno"
    }
    ,
    {
        image: require("../images/salotto_divano.jpg"),
        desc: {
            it: "Salotto in versione divano con tv",
            en: "Living room in sofa version with TV"
        },
        alt: "salotto divano"
    }
    ,
    {
        image: require("../images/salotto_letto.jpg"),
        desc: {
            it:"Salotto in versione letto doppio",
            en: "Living room in double bed version"
        },
        alt: "salotto letto"
    }
    ,
    {
        image: require("../images/vista.jpg"),
        desc: {
            it:"Ogni camera con vista sulla natura",
            en: "Each room with a view of nature"
        },
        alt: ""
    }
    ,
    {
        image: require("../images/tavolo esterno.jpg"),
        desc: {
            it: "Patio esterno con gazebo ombreggiante",
            en: "Outdoor lounge with gazebo"
        },
        alt: "salotto esterno"
    }
    ,
    {
        image: require("../images/piscina_lettini.jpg"),
        desc: {
            it:"Piscina con lettini",
            en: "Swimming pool with sun beds"
        },
        alt: "piscina"
    }
    ,
    {
        image: require("../images/vegetazione.jpg"),
        desc: {
            it:"Ampio oliveto circostante",
            en: "Large surrounding olive grove"
        },
        alt: "uliveto"
    }
    ,

]
const CardCollection = (props) => (
    <Fragment>
        <div className="article-collection">
            {
                cards.map(c =>
                    <Card
                        key={c.alt}
                        cardMobile="w-11/12"
                        data={c}
                        cardCollection="mr-8 relative"
                        lang={props.lang}
                    />
                )
            }
        </div>
    </Fragment>
)

export default CardCollection;
