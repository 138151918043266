import React from "react";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import BigTextLittleText from "./BigTextLittleText";
import VerticalLine from "./VerticalLine";
import HeroV from "../images/ceglie.jpg"
import HeroH from "../images/geco.jpg"
import "../styles.css";
import ToutContent from "./ToutContent";

const ceglie_images = [
    require("../images/27.jpg"),
]

const messapica_images=[
    require("../images/ceglie.jpg"),
    require("../images/30.jpg"),
]

const cibo_images =[
    require("../images/28.jpg"),
    require("../images/29.jpg"),
    require("../images/32.jpg"),
    require("../images/6.jpeg"),
]
const texts = {
    ceglie: {
        it: {
            text: "Ceglie Messapica, città d’arte e terra di gastronomia, così recita il cartello che ci accoglie al nostro " +
                "arrivo. Meno nota e affollata di altre città, è una vera perla. Il suo centro storico, arroccato su una " +
                "collina a 300m di altezza, attorno al Castello Ducale, di epoca medioevale, e alla Torre Normanna ha " +
                "mantenuto il suo aspetto antico.",
        },
        en: {
            text:"CEGLIE MESSAPICA, CITY OF ART AND A LAND OF GASTRONOMY, THUS IS THE SIGN THAT WELCOMES US ON OUR ARRIVAL. " +
                "LESS KNOWN AND CROWDED THAN OTHER CITIES, IT IS A REAL PEARL. ITS HISTORICAL CENTER, PERCHED ON A HILL 300M HIGH, " +
                "AROUND THE DUCAL CASTLE, OF THE MEDIEVAL PERIOD, AND THE NORMAN TOWER HAS KEPT ITS ANCIENT APPEARANCE."
        }

    },
    citta: {
        it: {
            text: "Il cuore della vita cittadina è Piazza Plebiscito dominata dalla Torre dell’Orologio. " +
                "Qui frequentemente vengono organizzati eventi che possono essere osservati tranquillamente " +
                "da uno dei bar che si affacciano sulla piazza. Perdersi dopo il tramonto negli stretti vicoli, " +
                "tra case imbiancate con la calce , scalinate e  balconi fioriti è un’esperienza emozionante." +
                "Capiterà poi di imbattersi in alcuni dei numerosi locali che hanno reso nota Ceglie Messapica e" +
                " la sua cucina tipica in giro per il mondo. \n",
        },
        en: {
            text: "THE HEART OF CITY LIFE IS PIAZZA PLEBISCITO DOMINATED BY THE CLOCK TOWER. EVENTS ARE FREQUENTLY " +
                "ORGANIZED HERE THAT CAN BE QUIETLY VIEWED FROM ONE OF THE BARS OVERLOOKING THE SQUARE. LOSING AFTER THE " +
                "SUNSET IN THE NARROW ALLEYS, AMONG HOUSES WHITENED WITH LIME, STAIRS AND FLOWERY BALCONIES IS AN EXCITING EXPERIENCE. " +
                "THEN YOU WILL HAPPEN IN SOME OF THE NUMEROUS LOCALS THAT HAVE MADE CEGLIE MESSAPICA NOTED AND ITS TYPICAL CUISINE AROUND THE WORLD.",
        }
    },
    cucina: {
        it: {
            text: "Oltre alla cucina tradizionale pugliese, bisogna assolutamente assaggiare due produzioni autoctone: " +
                "il panino cegliese e il biscotto cegliese, a base di mandorle e che negli " +
                "anni è diventato presidio Slow Food." +
                "Meritano sicuramente una visita i numerosi forni a legna dove si producono in maniera tradizionale pane, focacce, frise e taralli. \n" +
                "Per gli amanti dei formaggi il territorio offre la possibilità di acquistare direttamente dal produttore, visitando le " +
                "splendide masserie. \n",
        },
        en: {
            text: "IN ADDITION TO THE TRADITIONAL APULIAN CUISINE, IT IS ABSOLUTELY NEED TO TASTE TWO NATIVE PRODUCTIONS: " +
                "THE CEGLIESE PANINO AND THE CEGLIESE BISCUIT, BASED ON ALMONDS AND WHICH HAS BECOME A SLOW FOOD PRESIDIO IN THE YEARS. " +
                "BREAD, FOCACCE, FRIZE AND TARALLI. FOR CHEESE LOVERS THE TERRITORY OFFERS THE POSSIBILITY OF PURCHASING DIRECTLY FROM " +
                "THE PRODUCER ON THE SPOT, VISITING THE SPLENDID FARMHOUSES WHICH ARE STILL ACTIVE. \n",
        },
    }


}


const DintorniContainer = (props) => {
    React.useEffect(() => {
        console.log(props.lang);
        props.setNav(true);
        props.setFoot(true);
    });

    return(
        <ParallaxProvider>
            <div className="App">
                <div className="w-screen h-screen page-hero" style={{backgroundImage: `url(${window.innerWidth >= 560 ? HeroH : HeroV})`}}/>
                <BigTextLittleText title={"Ceglie"} text={""} lineTrigger={"lineCeglie"} class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineCeglie"}/>
                <div className="bg-quiete w-full">
                    <ToutContent
                        textDirection="toutContent w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row text-left sm:text-right bg-quiete"
                        text = {texts.ceglie[props.lang]["text"]}
                        image = {ceglie_images[Math.floor(Math.random() * ceglie_images.length)]}
                        alt={"image"}
                        class={"toutText--small"}
                        withLink={null}
                    />
                </div>

                <BigTextLittleText title={"Méssapica"} text={""} lineTrigger={"lineMessapica"} class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineMessapica"}/>
                <div className="bg-natura w-full">
                    <ToutContent
                        textDirection="toutContent w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row-reverse text-left bg-natura"
                        text = {texts.citta[props.lang]["text"]}
                        image = {messapica_images[Math.floor(Math.random() * messapica_images.length)]}
                        alt={"image"}
                        class={"toutText--small"}
                        withLink={null}
                    />
                </div>
                <BigTextLittleText title={"Eccellenza"} text={""} lineTrigger={"lineEccellenza"} class={"foreground-big-text"}/>
                <VerticalLine lineTrigger={"#lineEccellenza"}/>
                <div className="bg-tradizione w-full">
                    <ToutContent
                        textDirection="toutContentLast w-full my-0 mx-auto py-20 sm:py-0 flex flex-col-reverse sm:flex-row text-left sm:text-right bg-tradizione"
                        text = {texts.cucina[props.lang]["text"]}
                        image = {cibo_images[Math.floor(Math.random() * cibo_images.length)]}
                        alt={"image"}
                        class={"toutText--small"}
                        withLink={null}
                    />
                </div>
            </div>
        </ParallaxProvider>
    )
};

export default DintorniContainer;
