import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({embedId}) => (
    <iframe
            width="853"
            height="480"
            src="https://www.youtube-nocookie.com/embed/uBb44hmBtpU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
    >
    </iframe>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;