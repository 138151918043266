import React, {Fragment} from "react";

const ArticleHeader = (props) => (
    <Fragment>
        <p className="article-intro w-4/5 text-xl sm:text-4xl">
            {props.text}
        </p>
    </Fragment>
);

export default ArticleHeader;
