import React from "react";
import classnames from "classnames";
import logo from "../images/logo.png";
import {Link} from "react-router-dom";

const NavBar = () => {
    const [width, setWidth] = React.useState(window.innerWidth)
    const [prevScrollpos, setPrevScrollpos] = React.useState(window.pageYOffset)
    const [visible, setVisible] = React.useState(true)
    const [darken, setDarken] = React.useState(true)
    const [lang, setLang] = React.useState(window.location.href.split("/")[3])

    React.useEffect(() => {
        let l = window.location.href.split("/")[3]
        lang !== l && setLang(window.location.href.split("/")[3])
    })
    
    
    React.useEffect(() => {
        function handleResize() {
            setWidth({
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })
    React.useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible_ = prevScrollpos > currentScrollPos;

            setPrevScrollpos(currentScrollPos)
            visible_ !== visible && setVisible(visible_)
        };

        window.addEventListener("scroll", handleScroll);

        return _ => {
            window.removeEventListener("scroll", handleScroll);
        }
    })

    const MenuItems = {
        it: ["Introduzione", "Casa", "Dintorni", "Vita"],
        en: ["Intro", "Home", "Surroundings", "Life"]
    }

    const MenuLarge = () => {
        return (
            <React.Fragment>
                <div className="nav-bar__logo">
                    <a style={{height: "inherit"}} href={"/"}>
                        <img
                            className={"nav-logo"}
                            src={logo}
                            alt="logo"
                            loading={"lazy"}
                        />
                    </a>
                </div>
                <div className={"nav-bar__items"}>
                    <ul>
                        <li><Link to={`/${lang}/intro`}>{MenuItems[lang][0]}</Link></li>
                        <li><Link to={`/${lang}/casa`}>{MenuItems[lang][1]}</Link></li>
                        <li><Link to={`/${lang}/dintorni`}>{MenuItems[lang][2]}</Link></li>
                        <li><Link to={`/${lang}/vita`}>{MenuItems[lang][3]}</Link></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }

    const MenuSmall = () => {
        return (
            <React.Fragment>
                <div className="nav-bar__logo">
                    <img
                        className={"nav-logo"}
                        src={logo}
                        loading={"lazy"}
                        alt="logo"
                    />
                </div>
                <div>
                    <ul className="">
                        <li><Link to={`/${lang}/intro`}>{MenuItems[lang][0]}</Link></li>
                        <li><Link to={`/${lang}/casa`}>{MenuItems[lang][1]}</Link></li>
                    </ul>
                </div>
                <div>
                    <ul className="">
                        <li><Link to={`/${lang}/dintorni`}>{MenuItems[lang][2]}</Link></li>
                        <li><Link to={`/${lang}/vita`}>{MenuItems[lang][3]}</Link></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div
            className={classnames(
                "nav-bar flex", {
                    "nav-bar--hidden": !visible
                },
                {"nav-bar--darken": darken}
            )}
        >
            <div className="nav-bar__cont">
                {width > 600 ? <MenuLarge logo={logo} lang={lang}/> :
                    <MenuSmall logo={logo} lang={lang}/>}
            </div>
        </div>
    );
}


export default NavBar;
