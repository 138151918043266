import React from "react";

const ToutText = props => (
  <div className= {props.class + " mr-3 sm:mx-0"}>
    <p>
        {props.text}
    </p>
  </div>
);

export default ToutText;
