import React from "react";
import Fade from "react-reveal/Fade";
import ToutText from "./ToutText";
import Cta from "./Cta";

const ToutCopy = props => (

    <div className="toutCopy w-full sm:w-1/2">
        <Fade bottom>
            <ToutText text={props.text} class={props.class}/>
            <React.Fragment>{props.withLink ? <Cta withLink={props.withLink} text={props.textCta}/> : null}</React.Fragment>
        </Fade>
    </div>
)

export default ToutCopy;
